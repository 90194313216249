// 薪酬-设置-企业账号
import {
  WAGE
} from '../api'
import {
  METHOD,
  request
} from '@/utils/request'

// 档案统计信息
export async function getStaffFixedSalaryStatistics(params) {
  return request(`${WAGE}/archives/fixedSalary/getStaffFixedSalaryStatistics`, METHOD.GET, params)
}
// 薪资档案列表
export async function staffFixedSalaryList(params) {
  return request(`${WAGE}/archives/fixedSalary/staffFixedSalaryList`, METHOD.GET, params)
}

// 获取薪资固定项
export async function getSalaryItemList(params) {
  return request(`${WAGE}/salaryItem/getSalaryItemList`, METHOD.GET, params)
}

// 新增薪资固定项
export async function createFixedSalaryItem(params) {
  return request(`${WAGE}/salaryItem/createFixedSalaryItem`, METHOD.POST, params)
}

// 人员定薪
export async function setStaffFixedSalary(params) {
  return request(`${WAGE}/archives/fixedSalary/setStaffFixedSalary`, METHOD.POST, params)
}

// 下载批量定薪模板
export async function exportBatchSetTemplate(params) {
  return request(`${WAGE}/archives/fixedSalary/exportBatchSetTemplate`, METHOD.GET, params, {
    responseType: 'blob',
    headers: {
      isDownload: true
    }
  })
}

// 人员批量定薪
export async function batchSetFixedSalary(params, config) {
  return request(`${WAGE}/archives/fixedSalary/batchSetFixedSalary`, METHOD.POST, params, config)
}

// 人员调薪
export async function updateStaffFixedSalary(params) {
  return request(`${WAGE}/archives/fixedSalary/updateStaffFixedSalary`, METHOD.POST, params)
}

// 下载批量调薪模板
export async function exportBatchUpdateTemplate(params) {
  return request(`${WAGE}/archives/fixedSalary/exportBatchUpdateTemplate`, METHOD.GET, params, {
    responseType: 'blob',
    headers: {
      isDownload: true
    }
  })
}

// 人员批量调薪
export async function batchUpdateFixedSalary(params, config) {
  return request(`${WAGE}/archives/fixedSalary/batchUpdateFixedSalary`, METHOD.POST, params, config)
}

// 固定薪资调整历史
export async function getStaffFixedSalaryHistory(params) {
  return request(`${WAGE}/archives/fixedSalary/getStaffFixedSalaryHistory`, METHOD.GET, params)
}

// 导出薪资档案列表
export async function exportStaffFixedList(params) {
  return request(`${WAGE}/archives/fixedSalary/exportStaffFixedList`, METHOD.GET, params, {
    responseType: 'blob',
    headers: {
      isDownload: true
    }
  })
}
