var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main-content" },
    [
      !_vm.componentName
        ? _c("wageArchivesTabel", {
            attrs: { keyval: _vm.keyval },
            on: { change: _vm.callback },
          })
        : _vm._e(),
      _vm.componentName === "batchSetFixedSalary"
        ? _c("BatchImport", {
            attrs: {
              multiple: false,
              "file-size": 20,
              title: "人员批量定薪",
              "import-template": "batchSetFixedSalary",
            },
            on: { goBack: _vm.importComplate },
          })
        : _vm._e(),
      _vm.componentName === "batchUpdateFixedSalary"
        ? _c("BatchImport", {
            attrs: {
              multiple: false,
              "file-size": 20,
              title: "人员批量调薪",
              "import-template": "batchUpdateFixedSalary",
            },
            on: { goBack: _vm.importComplate },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }